import React, { Component } from "react";
import { web_title } from "../../constant";
import Section1a from "./images/section-1.1a.png";
import Section1b from "./images/section-1.1b.png";
import Section1c from "./images/section-1.1c.png";
import Section1d from "./images/section-1.1d.png";
import Section1 from "./images/section-1.svg";
import Section2a from "./images/section-2.1a.svg";
import Section3a from "./images/section-3.1a.svg";
import Section3b from "./images/section-3.1b.svg";
import Section3 from "./images/section-3.svg";
import Section4 from "./images/section-4.svg";

import BcaFinance from "./images/bca-finance.png";
import HanGang from "./images/han-gang.png";
import KemOlahraga from "./images/kementerian-olahraga.png";
import RsTabrani from "./images/rs-tabrani.png";

import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Reveal from "react-reveal/Reveal";
import { Link } from "react-router-dom";
import { scroller } from "react-scroll";
import CorporateVideo from "../../assets/corporate-video.mp4";
import FloatBlueLeft from "../../assets/float-blue-left.svg";
import FloatBlueRight from "../../assets/float-blue-right.svg";
import FloatOrangeLeft from "../../assets/float-orange-left.svg";
import FloatOrangeRight from "../../assets/float-orange-right.svg";

export default class Home extends Component {
  constructor(props) {
    super();
    this.corporateVideoRef = React.createRef();
    this.state = {
      clients: [
        {
          id: 1,
          name: "Kementerian Pemuda dan Olah Raga",
          img: KemOlahraga,
          company: "Kementerian Pemuda dan Olah Raga",
          testimonial:
            "Sistem yang kami miliki dibuat dengan user interface yang nyaman dilihat dan mudah dimengerti.",
        },
        {
          id: 2,
          name: "Rumah Sakit Prof. Dr. Tabrani",
          img: RsTabrani,
          company: "Rumah Sakit Prof. Dr. Tabrani",
          testimonial:
            "Pelayanan yang diberikan sangat memuaskan, sistem yang dibuat juga sudah sesuai dengan peraturan Menteri Kesehatan Republik Indonesia.",
        },
        {
          id: 3,
          name: "Han Gang Korean Food Art Restaurant",
          img: HanGang,
          company: "Han Gang Korean Food Art Restaurant",
          testimonial:
            "Dengan sistem yang tertata, mempermudah kami untuk melihat reservasi, menghitung data penjualan, sehingga pekerjaan finance dan accounting kami menjadi lebih efisien.",
        },
        {
          id: 4,
          name: "BCA Finance",
          img: BcaFinance,
          company: "BCA Finance",
          testimonial:
            "Tim Entliven terbukti profesional dibidangnya dan kami sangat puas dengan pelayanan yang diberikan. Very Excellent!",
        },
      ],
      client: null,
    };
  }

  componentDidMount() {
    const _self = this;
    window.scroll(0, 0);
    this.getClients(false);

    //this.corporateVideoRef.current.volume = 0.2;
    window.$("#corporateModal").on("hidden.bs.modal", function (e) {
      _self.corporateVideoRef.current.pause();
      window.$(document).find("iframe").html("").attr({
        src: "",
        style: "",
      });
    });
  }

  handleClickImageVideo(e) {
    e.preventDefault();
    window.$("#corporateModal").modal("show");
    //alert("Gogo");
  }

  handleHideVideoModal(e) {
    e.preventDefault();
    window.$("#corporateModal").modal("hide");
    //alert("Gogo");
  }

  getClients(e) {
    if (e) {
      this.setState({
        client: e,
      });
    } else {
      this.setState({
        client: this.state.clients[0],
      });
    }
  }

  scrollTo(pagename) {
    scroller.scrollTo(pagename, {
      duration: 1500,
      delay: 0,
      smooth: true,
      offset: -150,
    });
  }

  render() {
    return (
      <div className="home">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{web_title}</title>
          <meta
            name="description"
            content="PT Entliven Teknologi Internasional | IT School &amp; Software Development adalah Perusahaan IT berteknologi modern yang hadir untuk menjawab kebutuhan bisnis Anda, memberi kemudahan dengan penggunaan teknologi informasi terkini."
          ></meta>
          <meta
            name="keywords"
            content="Work Hard, Then Time Will Never Betray The Results, Layanan, Testimonial"
          ></meta>
        </Helmet>
        <div
          className="modal fade"
          id="corporateModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="corporateModalLabel"
          aria-hidden="true"
          data-backdrop="static"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="corporateModalLabel">
                  Corporate Video
                </h5>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={this.handleHideVideoModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <video
                  ref={this.corporateVideoRef}
                  width="100%"
                  height="50%"
                  controls
                >
                  <source src={CorporateVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>
        <div className="header-section space-20-bottom">
          <div className="container">
            <div className="row">
              <div className="col-sm-5 header-intro mb-5 mb-sm-0">
                <div>
                  <Reveal effect="">
                    <h2 className="font-weight-bold text-grey-100">
                      Work Hard,
                    </h2>
                    <h2 className="font-weight-bold text-grey-100">
                      Then Time Will Never
                    </h2>
                    <h2 className="font-weight-bold text-grey-100 mb-sm-4 mb-3">
                      Betray The Results
                    </h2>
                    <p className="font-body text-grey-100">
                      PT Entliven Teknologi Internasional | IT School &amp;
                      Software Development adalah Perusahaan IT berteknologi
                      modern yang hadir untuk menjawab kebutuhan bisnis Anda,
                      memberi kemudahan dengan penggunaan teknologi informasi
                      terkini.
                    </p>
                    <button
                      className="btn-orange-light font-body font-weight-bold text-grey-100 cursor-pointer mt-sm-5"
                      onClick={() => this.scrollTo("memilih-kami")}
                    >
                      Selengkapnya
                    </button>
                  </Reveal>
                </div>
              </div>
              <div className="col-sm-7 d-flex justify-content-center pl-lg-5">
                <img
                  className="float1 pulse pulse-orange absolute d-width-60 delay-1s"
                  src={FloatOrangeLeft}
                  alt="float1"
                ></img>
                <img
                  className="float2 pulse pulse-orange absolute d-width-40 delay-2s"
                  src={FloatOrangeRight}
                  alt="float3"
                ></img>
                <img
                  className="float3 pulse pulse-blue absolute d-width-40 delay-3s"
                  src={FloatBlueLeft}
                  alt="float3"
                ></img>
                <img
                  className="float4 pulse pulse-blue absolute d-width-60 delay-4s"
                  src={FloatBlueRight}
                  alt="float4"
                ></img>

                <Reveal effect="zoomIn">
                  <img
                    className="section-1 img-responsive"
                    src={Section1}
                    alt="section-1"
                  ></img>
                </Reveal>
              </div>
              <div className="col-sm-6">
                <div className="header-partner">
                  <div className="d-flex justify-content-between">
                    <Reveal effect="zoomIn">
                      <img
                        className="section-1a img-partner"
                        src={Section1a}
                        alt="section-1a"
                      ></img>
                      <img
                        className="section-1b img-partner"
                        src={Section1b}
                        alt="section-1b"
                      ></img>
                      <img
                        className="section-1c img-partner"
                        src={Section1c}
                        alt="section-1c"
                      ></img>
                      <img
                        className="section-1d img-partner"
                        src={Section1d}
                        alt="section-1d"
                      ></img>
                    </Reveal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="provide-section space-20-bottom"
          name="memilih-kami"
          id="memilih-kami"
        >
          <div className="container">
            <div className="row d-flex flex-wrap-reverse flex-lg-mowrap">
              <div className="col-sm-7">
                <div className="section-2">
                  <img
                    className="float5 float absolute d-width-60 delay-3s"
                    src={FloatBlueLeft}
                    alt="float5"
                  ></img>
                  <Reveal effect="zoomIn">
                    <a
                      href="#"
                      onClick={this.handleClickImageVideo}
                      title="Show Corporate Video"
                    >
                      <LazyLoadImage
                        src={Section2a}
                        effect="blur"
                        width="100%"
                        height="auto"
                      />
                    </a>
                  </Reveal>
                </div>
              </div>
              <div className="col-sm-5 d-flex align-items-sm-center mb-5 mb-sm-0">
                <div>
                  <Reveal effect="zoomIn">
                    <h2 className="font-weight-bold text-center text-lg-left">
                      <span className="text-black">Mengapa Harus </span>
                    </h2>
                    <h2 className="font-weight-bold mb-3 mb-sm-4 text-center text-lg-left">
                      <span className="text-orange-light">Memilih Kami?</span>
                    </h2>
                    <p className="font-body text-grey-600 mb-5 text-center text-lg-left">
                      Entliven merupakan solusi bagi Anda untuk mengembangkan
                      teknologi modern dan terkini, membuat bisnis Anda menjadi
                      lebih profesional dan menghasilkan margin/pendapatan
                      sesuai dengan yang Anda harapkan. Kami selalu bertumbuh
                      menjadi kreatif dan ahli dalam melihat peluang, serta
                      mempertahankan layanan dan meningkatkan produk kami guna
                      menciptakan ide-ide baru.
                    </p>
                    <div className="d-flex justify-content-center justify-content-sm-start">
                      <button
                        className="btn-orange-light font-body font-weight-bold text-grey-100 cursor-pointer mb-4 m-sm-0 mb-sm-0"
                        onClick={() => this.scrollTo("layanan-kami")}
                      >
                        Dapatkan Layanan Kami
                      </button>
                    </div>
                  </Reveal>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="service-section space-20-bottom pb-5 pb-sm-0"
          name="layanan-kami"
          id="layanan-kami"
        >
          <div className="container">
            <div className="row">
              <div className="col-sm-5 d-flex align-items-sm-center mb-5 mb-sm-0">
                <div>
                  <Reveal effect="zoomIn">
                    <h2 className="font-weight-bold mb-3 mb-sm-4 text-center text-lg-left">
                      <span className="text-black">Layanan </span>
                      <span className="text-orange-light">Kami</span>
                    </h2>
                    <p className="font-body text-grey-600 mb-5 text-center text-lg-left">
                      Entliven memberikan layanan yang telah teruji dengan baik.
                      Berdasar pada standar layanan pengembang software, dimana
                      layanan kami terbagi menjadi dua bagian yaitu,
                      pengembangan website dan mobile apps. Kedua layanan kami
                      didasari dengan strategi terbaik, kreativitas, desain dan
                      didukung tenaga ahli dibidangnya.
                    </p>
                    <div className="d-sm-flex justify-content-center justify-content-sm-start">
                      <Link to="/development">
                        <button className="d-block btn-outline-blue-light font-body font-weight-bold text-grey-100 mb-3 m-sm-0 cursor-pointer">
                          Development
                        </button>
                      </Link>
                      <Link to="/school">
                        <button className="d-block btn-orange-light font-body font-weight-bold text-grey-100 m-sm-0 mb-3 cursor-pointer ml-sm-3">
                          IT School
                        </button>
                      </Link>
                    </div>
                  </Reveal>
                </div>
              </div>
              <div className="col-sm-7">
                <img
                  className="float6 float absolute set-backward d-width-50 delay-1s"
                  src={FloatOrangeLeft}
                  alt="float6"
                ></img>
                <img
                  className="float7 float absolute set-backward d-width-60 delay-2s"
                  src={FloatOrangeRight}
                  alt="float7"
                ></img>
                <Reveal effect="zoomIn">
                  <div className="section-3">
                    <img
                      className="section-3 img-responsive d-none d-lg-block"
                      src={Section3}
                      alt="section-3"
                    ></img>
                    <div className="service-wrapper d-sm-flex">
                      <div className="service-content">
                        <div className="service-desc text-center">
                          <p className="font-body text-grey-600 mb-4">
                            Pembuatan Software Engineer/Software House
                            Production. Kami memproduksi system/software yang
                            sesuai dengan kebutuhan klien.
                          </p>
                        </div>
                        <img
                          className="section-3a img-partner"
                          src={Section3a}
                          alt="section-3a"
                        ></img>
                      </div>
                      <div className="service-content">
                        <div className="service-desc text-center">
                          <p className="font-body text-grey-600 mb-4">
                            Kedepannya kami akan mendirikan sekolah bagi Anda
                            yang berminat untuk mengembangkan diri dan potensi
                            Anda di bidang IT dan Development.
                          </p>
                        </div>
                        <img
                          className="section-3b img-partner"
                          src={Section3b}
                          alt="section-3b"
                        ></img>
                      </div>
                    </div>
                  </div>
                </Reveal>
              </div>
            </div>
          </div>
        </div>

        <div
          className="client-section space-20-bottom"
          name="klien-kami"
          id="klien-kami"
        >
          <div className="container">
            <div className="row d-flex flex-wrap-reverse flex-lg-mowrap">
              <div className="col-sm-7 mb-3">
                {this.state.client ? (
                  <div className="section-4 client-content pr-lg-5">
                    <img
                      className="float8 float absolute d-width-60 delay-3s"
                      src={FloatBlueLeft}
                      alt="float8"
                    ></img>
                    <img
                      className="float9 float absolute d-width-60 delay-5s"
                      src={FloatBlueRight}
                      alt="float9"
                    ></img>
                    <Reveal effect="zoomIn client-chosen">
                      <img
                        className="section-4a set-forward img-fluid"
                        src={this.state.client.img}
                        alt="section-4a"
                      ></img>
                      <LazyLoadImage
                        src={Section4}
                        effect="blur"
                        width="100%"
                        height="auto"
                      />
                      <div className="client-caption">
                        <p className="font-body text-grey-100 font-weight-bold">
                          {this.state.client.company}
                        </p>
                        <p className="font-body text-grey-100 ">
                          {'"'}
                          {this.state.client.testimonial}
                          {'"'}
                        </p>
                      </div>
                    </Reveal>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-sm-5 d-flex align-items-sm-center">
                <div>
                  <Reveal effect="zoomIn">
                    <h2 className="font-weight-bold text-center text-lg-left mb-3 mb-sm-4">
                      <span className="text-black">Apa Kata </span>
                      <span className="text-orange-light">Klien </span>
                      <span className="text-black">Kami?</span>
                    </h2>
                    <p className="font-body text-grey-600 mb-5 text-center text-lg-left">
                      Kami telah mengerjakan project-project besar dari berbagai
                      klien, mulai dari perusahaan, restoran, rumah sakit,
                      hotel, bank, hingga instansi pemerintahan. Kepuasan klien
                      adalah prioritas utama kami.
                    </p>
                  </Reveal>
                  <div className="row justify-content-center">
                    <Reveal effect="zoomIn">
                      {this.state.clients.map((e) => (
                        <div
                          className="col-6 pl-5 pr-5 mb-4 mb-sm-5"
                          key={e.id}
                        >
                          <img
                            className="section-4b img-fluid cursor-pointer"
                            src={e.img}
                            alt="section-4b"
                            onClick={() => this.getClients(e)}
                          ></img>
                        </div>
                      ))}
                    </Reveal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
