import React, { Component } from "react";
import { Helmet } from "react-helmet";

export default class News extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }

  render() {
    return (
      <div className="privacy-policy">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Entliven | Privacy Policy</title>
        </Helmet>
      </div>
    );
  }
}
