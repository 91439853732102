import React, { Component } from "react";
import { web_title } from "../../constant";
import Section1 from "./images/section-1.svg";
import Section3 from "./images/section-3.svg";
import Service1 from "./images/service-1.svg";
import Service2 from "./images/service-2.svg";
import Service3 from "./images/service-3.svg";
import Service4 from "./images/service-4.svg";
import Service5 from "./images/service-5.svg";
import Service6 from "./images/service-6.svg";
import Product1a from "./images/product-1a.png";
import Product1b from "./images/product-1b.png";
import Product1c from "./images/product-1c.png";

import IconChevronRight from "../../components/SVG/IconChevronRight";

import Reveal from "react-reveal/Reveal";
import { Helmet } from "react-helmet";
import { scroller } from "react-scroll";

export default class School extends Component {
  constructor(props) {
    super();
    this.state = {
      agencies: [
        {
          id: 1,
          name: "Web & Android Development",
          img: Product1a,
          desc: "ENTLIVEN is modern technology application and we are here to answer your business needs with the ease of using information technology today. ENTLIVEN is modern technology application and we are here to answer your business needs with the ease of using information technology today.",
        },
        {
          id: 2,
          name: "Graphic Design & Video Editing",
          img: Product1b,
          desc: "ENTLIVEN is modern technology application and we are here to answer your business needs with the ease of using information technology today. ENTLIVEN is modern technology application and we are here to answer your business needs with the ease of using information technology today.",
        },
        {
          id: 3,
          name: "SEO",
          img: Product1c,
          desc: "ENTLIVEN is modern technology application and we are here to answer your business needs with the ease of using information technology today. ENTLIVEN is modern technology application and we are here to answer your business needs with the ease of using information technology today.",
        },
      ],
    };
  }

  scrollTo(pagename) {
    scroller.scrollTo(pagename, {
      duration: 1500,
      delay: 0,
      smooth: true,
      offset: -150,
    });
  }

  componentDidMount() {
    window.scroll(0, 0);
    this.getAgencies(false);
  }

  getAgencies(e) {
    if (e) {
      this.setState({
        agency: e,
      });
    } else {
      this.setState({
        agency: this.state.agencies[0],
      });
    }
  }

  render() {
    return (
      <div className="school">
        <Helmet>
          <meta charSet="utf-8" />
          <title>IT School - {web_title}</title>
          <meta
            name="description"
            content="Kedepannya kami akan mendirikan sekolah bagi Anda yang berminat untuk mengembangkan diri dan potensi Anda di bidang IT dan Development."
          ></meta>
        </Helmet>
        <div className="header-section space-20-bottom">
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <Reveal effect="zoomIn">
                  <h2 className="font-weight-bold text-grey-100">
                    COMING SOON!
                  </h2>

                  <p className="font-body text-grey-100 mb-sm-5">
                    Kedepannya kami akan mendirikan sekolah bagi Anda yang
                    berminat untuk mengembangkan diri dan potensi Anda di bidang
                    IT dan Development.
                  </p>
                  <a href="/contact">
                    <button
                      className="btn-blue-light font-body font-weight-bold text-grey-100 cursor-pointer mb-sm-5"
                      // onClick={() => this.scrollTo("layanan-kami")}
                    >
                      Hubungi Kami
                    </button>
                  </a>
                </Reveal>
              </div>
              <div className="col-sm-6">
                <Reveal effect="zoomIn">
                  <img
                    className="header-bg img-fluid float-right"
                    src={Section1}
                    alt="header-bg"
                  ></img>
                </Reveal>
              </div>
            </div>
          </div>
        </div>

        {this.state.hideElement ? (
          <div className="container" name="layanan-kami" id="layanan-kami">
            <div className="services-section row space-20-bottom">
              <div className="col-sm-8 offset-sm-2">
                <Reveal effect="zoomIn">
                  <h2 className="font-weight-bold text-center mb-3 mb-sm-4">
                    <span className="text-grey-900">Our </span>
                    <span className="text-blue-light">Services</span>
                  </h2>
                  <p className="font-body text-grey-600 text-center mb-sm-5 pr-sm-5 pl-sm-5">
                    ENTLIVEN is modern technology application and we are here to
                    answer your business needs with the ease of using
                    information technology today. ENTLIVEN is modern technology
                    application and we are here to answer your business needs
                    with the ease of using information technology today.
                  </p>
                </Reveal>
                <div className="row">
                  <Reveal effect="zoomIn">
                    <div className="col-4 text-center">
                      <img
                        className="img-fluid pl-sm-5 pr-sm-5"
                        src={Service1}
                        alt="service-1"
                      ></img>
                      <h6 className="grey-700">
                        <span>Regular</span>
                        <br />
                        <span>Training</span>
                      </h6>
                    </div>
                    <div className="col-4 text-center mb-5">
                      <img
                        className="img-fluid pl-sm-5 pr-sm-5"
                        src={Service2}
                        alt="service-2"
                      ></img>
                      <h6 className="grey-700">
                        <span>Inhouse</span>
                        <br />
                        <span>Training</span>
                      </h6>
                    </div>
                    <div className="col-4 text-center mb-5">
                      <img
                        className="img-fluid pl-sm-5 pr-sm-5"
                        src={Service3}
                        alt="service-3"
                      ></img>
                      <h6 className="grey-700">
                        <span>Custom Case</span>
                        <br />
                        <span>Training</span>
                      </h6>
                    </div>
                    <div className="col-4 text-center">
                      <img
                        className="img-fluid pl-sm-5 pr-sm-5"
                        src={Service4}
                        alt="service-4"
                      ></img>
                      <h6 className="grey-700">
                        <span>Creativity</span>
                        <br />
                        <span>Agent</span>
                      </h6>
                    </div>
                    <div className="col-4 text-center">
                      <img
                        className="img-fluid pl-sm-5 pr-sm-5"
                        src={Service5}
                        alt="service-5"
                      ></img>
                      <h6 className="grey-700">
                        <span>Competency-Based</span>
                        <br />
                        <span>Training</span>
                      </h6>
                    </div>
                    <div className="col-4 text-center">
                      <img
                        className="img-fluid pl-sm-5 pr-sm-5"
                        src={Service6}
                        alt="service-6"
                      ></img>
                      <h6 className="grey-700">
                        <span>Permanent</span>
                        <br />
                        <span>Consulting</span>
                      </h6>
                    </div>
                    <div className="col-12">
                      <button
                        className="d-block btn-blue-light font-body font-weight-bold text-grey-100 cursor-pointer mt-4 mt-sm-5 mb-5 mb-sm-0"
                        style={{ width: "auto" }}
                      >
                        Download Content
                      </button>
                    </div>
                  </Reveal>
                </div>
              </div>
            </div>

            <div className="school-section row mb-5 d-flex flex-wrap-reverse flex-lg-mowrap space-20-bottom">
              <div className="col-sm-7">
                <Reveal effect="zoomIn">
                  <img
                    className="img-responsive"
                    src={Section3}
                    alt="section3"
                  ></img>
                  <div className="row">
                    <div className="col-5">
                      <h6 className="text-orange-light text-center">
                        <span>Web Development</span>
                        <br />
                        <span>School</span>
                      </h6>
                    </div>
                    <div className="col-5">
                      <h6 className="text-grey-700 text-center">
                        <span>Video Editing</span>
                        <br />
                        <span>School</span>
                      </h6>
                    </div>
                  </div>
                </Reveal>
              </div>
              <div className="col-sm-5 d-flex align-items-sm-center">
                <div>
                  <Reveal effect="zoomIn">
                    <h2 className="font-weight-bold mb-3 mb-sm-4 text-center text-md-left">
                      <span className="text-grey-900">IT </span>
                      <span className="text-blue-light">Schooling</span>
                    </h2>
                    <p className="font-body text-grey-600 text-center text-md-left">
                      ENTLIVEN is modern technology application and we are here
                      to answer your business needs with the ease of using
                      information technology today. ENTLIVEN is modern
                      technology application and we are here to answer your
                      business needs with the ease of using information
                      technology today.
                    </p>
                  </Reveal>
                </div>
              </div>
            </div>

            <div className="agency-section row space-20-bottom">
              <div className="col-sm-6">
                <Reveal effect="zoomIn">
                  <h2 className="font-weight-bold mb-3 mb-sm-4 text-center text-md-left">
                    <span className="text-grey-900">Creative </span>
                    <span className="text-blue-light">Agency</span>
                  </h2>
                  <p className="font-body text-grey-600 mb-4 text-center text-md-left">
                    ENTLIVEN is modern technology application and we are here to
                    answer your business needs with the ease of using
                    information technology today. ENTLIVEN is modern technology
                    application and we are here to answer your business needs
                    with the ease of using information technology today.
                  </p>
                  <div className="mb-3 mb-0-sm">
                    {this.state.agencies.map((e) => (
                      <div key={e.id}>
                        {this.state.agency ? (
                          <div>
                            <button
                              className={
                                e.id === this.state.agency.id
                                  ? "bg-blue-light btn-select mb-4"
                                  : "btn-select mb-4"
                              }
                              onClick={() => this.getAgencies(e)}
                            >
                              <div className="d-flex align-items-center justify-content-between">
                                <span
                                  className={
                                    e.id === this.state.agency.id
                                      ? "text-white"
                                      : "text-grey-700"
                                  }
                                >
                                  {e.name}
                                </span>
                                <IconChevronRight
                                  size="32"
                                  color={
                                    e.id === this.state.agency.id
                                      ? "#FFFFFF"
                                      : "#6c757d"
                                  }
                                />
                              </div>
                            </button>
                            <div
                              className={
                                e.id === this.state.agency.id
                                  ? "d-block d-sm-none"
                                  : "d-none"
                              }
                            >
                              <Reveal effect="zoomIn">
                                <img
                                  className="img-responsive"
                                  src={this.state.agency.img}
                                  alt="product1a"
                                ></img>
                                <div className="pl-sm-3">
                                  <h6 className="text-center text-md-left">
                                    {this.state.agency.name}
                                  </h6>
                                  <p className="font-body text-grey-700 text-center text-md-left">
                                    {this.state.agency.desc}
                                  </p>
                                </div>
                              </Reveal>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ))}
                  </div>
                </Reveal>
              </div>
              <div className="col-sm-6 d-none d-sm-block">
                {this.state.agency ? (
                  <Reveal effect="zoomIn">
                    <img
                      className="img-responsive"
                      src={this.state.agency.img}
                      alt="product1a"
                    ></img>
                    <div className="pl-sm-3">
                      <h6 className="text-center text-md-left">
                        {this.state.agency.name}
                      </h6>
                      <p className="font-body text-grey-700 text-center text-md-left">
                        {this.state.agency.desc}
                      </p>
                    </div>
                  </Reveal>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
