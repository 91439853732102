import React, { Component } from "react";
import { web_title } from "../../constant";
import Section1 from "./images/section-1.svg";
import Section2 from "./images/section-2.svg";
import Section31 from "./images/section-3.1.svg";
import Section34 from "./images/section-3.4.svg";
import FloatOrangeRight from "../../assets/float-orange-right.svg";
import FloatOrangeLeft from "../../assets/float-orange-left.svg";
import FloatBlueRight from "../../assets/float-blue-right.svg";
import FloatBlueLeft from "../../assets/float-blue-left.svg";
import Reveal from "react-reveal/Reveal";
import { Helmet } from "react-helmet";

export default class About extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }

  render() {
    return (
      <div className="about">
        <Helmet>
          <meta charSet="utf-8" />
          <title>About Us - {web_title}</title>
          <meta
            name="description"
            content="PT Entliven Teknologi Internasional | IT School &amp; Software Development adalah Perusahaan IT berteknologi modern yang hadir untuk menjawab kebutuhan bisnis Anda, memberi kemudahan dengan penggunaan teknologi informasi terkini."
          ></meta>
          <meta name="keywords" content="Tentang Kami, Visi, Misi, Sejarah"></meta>
        </Helmet>
        <div className="container">
          <div className="intro-section pt-5 space-20-bottom">
            <div className="row d-flex flex-wrap-reverse flex-lg-mowrap">
              <div className="col-sm-7 pr-lg-5">
                <img
                  className="float1 float absolute d-width-60 delay-1s"
                  src={FloatOrangeLeft}
                  alt="float1"
                ></img>
                <img
                  className="float2 float absolute d-width-30 delay-2s"
                  src={FloatOrangeRight}
                  alt="float2"
                ></img>
                <img
                  className="float3 float absolute d-width-40 delay-3s"
                  src={FloatOrangeLeft}
                  alt="float3"
                ></img>
                <img
                  className="float4 float absolute d-width-50 delay-4s"
                  src={FloatOrangeRight}
                  alt="float4"
                ></img>
                <Reveal effect="zoomIn">
                  <img
                    className="section-1 img-responsive"
                    src={Section1}
                    alt="section-1"
                  ></img>
                </Reveal>
              </div>
              <div className="col-sm-5 d-flex align-items-sm-center mb-5 mb-sm-0">
                <div>
                  <Reveal effect="zoomIn">
                    <h2 className="mb-3 mb-sm-4 text-center text-lg-left">
                      <span className="font-weight-bold text-grey-900">
                        Tentang{" "}
                      </span>
                      <span className="font-weight-bold text-orange-light">
                        Kami
                      </span>
                    </h2>
                    <p className="font-body text-grey-600 text-center text-lg-left">
                      PT Entliven Teknologi Internasional | IT School &amp; Software Development adalah Perusahaan IT berteknologi modern yang hadir
                      untuk menjawab kebutuhan bisnis Anda, memberi kemudahan
                      dengan penggunaan teknologi informasi terkini.
                    </p>
                  </Reveal>
                </div>
              </div>
            </div>
          </div>
          <div className="services-section space-20-bottom" id="visi-misi">
            <div className="row">
              <div className="col-sm-5 d-flex align-items-sm-center mt-5 mt-sm-0 mb-5 mb-sm-0">
                <div>
                  <Reveal effect="zoomIn">
                    <h2 className="mb-3 mb-sm-4 text-center text-lg-left">
                      <span className="font-weight-bold text-grey-900">
                        Apa yang{" "}
                      </span>
                      <span className="font-weight-bold text-orange-light">
                        Kami{" "}
                      </span>
                      <span className="font-weight-bold text-grey-900">
                        Kerjakan?
                      </span>
                    </h2>
                    <p className="font-body text-grey-600 text-center text-lg-left mb-4">
                      Pertumbuhan yang cepat dari Entliven merupakan hasil dari
                      mengidentifikasi tren terkini dan merespon kenaikan
                      permintaan pengembangan software, bidang pemasaran, bidang
                      perkantoran, dan bidang penjualan secara kreatif.
                    </p>
                    <h4 className="mb-3 text-center text-lg-left">
                      <span className="font-weight-bold text-orange-light">
                        Visi kami{" "}
                      </span>
                    </h4>
                    <p className="font-body text-grey-600 text-center text-lg-left mb-4">
                      Menjadi penyedia aplikasi software terbaik yang dapat
                      memahami kebutuhan Anda, agar perusahaan Anda bisa lebih
                      profesional dalam menjalankan bisnis.
                    </p>
                    <h4 className="mb-3 text-center text-lg-left">
                      <span className="font-weight-bold text-orange-light">
                        Misi kami{" "}
                      </span>
                    </h4>
                    <div className="font-body text-grey-600 text-center text-lg-left">
                      <ol className="pl-3 text-left">
                        <li>Kepuasan pelanggan adalah tujuan utama kami.</li>
                        <li>
                          Memudahkan pengguna software untuk menjalankan
                          perhitungan bisnis dan keuangan.
                        </li>
                        <li>
                          Mampu menyediakan produk yang bervariasi dengan
                          pilihan yang selalu diperbarui.
                        </li>
                        <li>
                          Selalu memberikan konsultasi terkini dan inovasi dalam
                          pengembangan dunia teknologi dan peraturan pemerintah.
                        </li>
                        <li>
                          Menyediakan produk berkualitas tinggi dengan harga
                          yang terjangkau.
                        </li>
                        <li>
                          Selalu memberikan kepuasan kepada pelanggan dengan
                          menjamin kualitas produk yang dibeli.
                        </li>
                      </ol>
                    </div>
                    {/* <div className="d-flex justify-content-center justify-content-sm-start">
										<button className="btn-orange-light font-body font-weight-bold text-grey-100 cursor-pointer ml-sm-0 mt-4 mb-4 mb-sm-0">Get Services</button>
									</div> */}
                  </Reveal>
                </div>
              </div>
              <div className="col-sm-7 pl-lg-5">
                <img
                  className="float5 float absolute d-width-50 delay-1s"
                  src={FloatBlueLeft}
                  alt="float5"
                ></img>
                <img
                  className="float6 float absolute d-width-80 delay-2s"
                  src={FloatBlueRight}
                  alt="float6"
                ></img>
                <img
                  className="float7 float absolute d-width-60 delay-3s"
                  src={FloatBlueRight}
                  alt="float7"
                ></img>
                <Reveal effect="zoomIn">
                  <img
                    className="section-2 img-responsive mb-5 mb-sm-0"
                    src={Section2}
                    alt="section-2"
                  ></img>
                </Reveal>
              </div>
            </div>
          </div>
          <div className="history-section space-20-bottom mt-4 mt-sm-0">
            <img
              className="section-31 img-responsive"
              src={Section31}
              alt="section-31"
            ></img>
            <img
              className="section-34 img-responsive"
              src={Section34}
              alt="section-34"
            ></img>
            <img
              className="float8 float absolute d-width-50 delay-1s"
              src={FloatOrangeLeft}
              alt="float8"
            ></img>
            <img
              className="float9 float absolute d-width-40 delay-2s"
              src={FloatOrangeRight}
              alt="float9"
            ></img>
            <img
              className="float10 float absolute d-width-50 delay-1s"
              src={FloatBlueLeft}
              alt="float10"
            ></img>
            <img
              className="float11 float absolute d-width-30 delay-2s"
              src={FloatBlueRight}
              alt="float11"
            ></img>
            <div className="row" id="sejarah">
              <div className="col-sm-10 offset-sm-1 mb-3">
                <div className="text-center">
                  <h2 className="mb-3 mb-sm-4">
                    <span className="font-weight-bold text-orange-light">
                      Sejarah
                    </span>
                    <span className="font-weight-bold text-grey-900">
                      {" "}
                      Entliven
                    </span>
                  </h2>
                  <p className="font-body text-grey-600">
                    PT ENTLIVEN TEKNOLOGI INTERNASIONAL bermula dari inisiasi 3
                    orang anak muda yang penuh dengan semangat, ide, visi dan
                    misi, serta berkomitmen kuat dengan membentuk Tim IT untuk
                    mengerjakan project-project kecil seperti pembuatan Website,
                    Jurnal Website, Profile Artikel Personal, dll. Dari
                    kesuksesan project kecil itu, kemudian dipercayakan project
                    yang lebih besar dan lebih serius dari berbagai perusahaan,
                    hotel, restoran, rumah sakit, hingga ke instansi
                    pemerintahan. Kami akan terus bertumbuh sesuai dengan arti
                    dari ENTLIVEN yang diambil dari Bahasa Belanda yang berarti
                    menjadi saluran berkat bagi banyak orang, terkhususnya para
                    klien kami.
                  </p>
                </div>
              </div>
              <div className="col-sm-12">
                <Reveal effect="zoomIn">
                  <ul className="timeline">
                    <li className="timeline-inverted">
                      <div className="timeline-badge primary">
                        <i
                          className="fas fa-circle clr-white stroke-transparent"
                          id=""
                        ></i>
                      </div>
                      <div className="timeline-panel bg-white wow  fadeInUp animated">
                        <div className="timeline-heading">
                          <h2 className="font-weight-bold text-grey-900">
                            2010
                          </h2>
                        </div>
                        <div className="timeline-body">
                          <p className="font-body text-grey-600">
                            Di tahun ini, Tim IT baru terbentuk dan belum
                            memiliki badan usaha. Titik awal kami memberanikan
                            diri untuk menerima dan mengerjakan project-project
                            kecil.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="timeline-not-inverted">
                      <div className="timeline-badge primary">
                        <i
                          className="fas fa-circle clr-white stroke-transparent wow  zoomIn animated"
                          id=""
                        ></i>
                      </div>
                      <div className="timeline-panel bg-white wow  fadeInUp animated">
                        <div className="timeline-heading">
                          <h2 className="font-weight-bold text-grey-900">
                            2011
                          </h2>
                        </div>
                        <div className="timeline-body">
                          <p className="font-body text-grey-600">
                            {" "}
                            Tim IT yang terbentuk sudah mulai solid dan kami
                            mulai meningkatkan project IT yang dikerjakan dengan
                            level sederhana.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="timeline-inverted">
                      <div className="timeline-badge primary">
                        <i
                          className="fas fa-circle clr-white stroke-transparent"
                          id=""
                        ></i>
                      </div>
                      <div className="timeline-panel bg-white wow  fadeInUp animated">
                        <div className="timeline-heading">
                          <h2 className="font-weight-bold text-grey-900">
                            2012 - 2018
                          </h2>
                        </div>
                        <div className="timeline-body">
                          <p className="font-body text-grey-600">
                            Personil Tim IT yang kami miliki semakin bertambah
                            banyak, dengan begitu kami dapat meningkatkan
                            kapasitas untuk dapat menerima project IT dengan
                            level yang lebih serius dan memulai memproduksi
                            software/system.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="timeline-not-inverted">
                      <div className="timeline-badge primary">
                        <i
                          className="fas fa-circle clr-white stroke-transparent wow  zoomIn animated"
                          id=""
                        ></i>
                      </div>
                      <div className="timeline-panel bg-white wow fadeInUp animated">
                        <div className="timeline-heading">
                          <h2 className="font-weight-bold text-grey-900">
                            2018 - Sekarang
                          </h2>
                        </div>
                        <div className="timeline-body">
                          <p className="font-body text-grey-600">
                            {" "}
                            Kami berhasil mendirikan PT ENTLIVEN TEKNOLOGI
                            INTERNASIONAL menjadi sebuah badan usaha yang
                            memiliki legalitas lengkap. Hingga saat ini, kami
                            telah menerima project-project besar dari berbagai
                            klien di Indonesia.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Reveal>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
